import { Trash2 } from 'lucide-react';
import {toast} from "react-toastify";
import {useDeleteAngajat} from "../../../services/apiAngajati";
import 'react-toastify/dist/ReactToastify.css';

const DeleteModal = ({ onClose, childText, angajat }) => {

    const {mutate: deleteAngajat} = useDeleteAngajat();


    const onSubmit = () => {
        deleteAngajat(angajat, {
            onSuccess:() => {
                toast.success('Angajatul a fost sters cu succes!')
                onClose();
            },
            onError: (error) => {
                console.error('Error deleting employee:', error);
                toast.error('A aparut o eroare în timpul stergerii angajatului.');
            }
        })
    }

  return (
    <div className='w-full items-center justify-center p-6 mx-0 flex flex-col'>
      <Trash2 size={46} color='#EF4444' />
      <h1 className='p-2 font-Jost text-titlecolor font-semibold text-heading-lg'>
        Confirmare stergere
      </h1>
      <p className='font-Jost text-textcolor font-regular text-body-lg'>
        Sunteti sigur ca doriti sa stergeti {childText}?
      </p>
      <div className='mt-4 flex justify-end'>
        <button
          onClick={() => onSubmit()}
          className='bg-success text-white px-4 py-2 rounded-md mr-2'
        >
          Confirm
        </button>
        <button
          //   onClick={() => setOpen(false)}
          className='bg-red-500 text-white px-4 py-2 rounded-md'
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
