import React from 'react';
import Topbar from '../Topbar/Topbar';
import Sidebar from '../Sidebar/Sidebar';
import Footer from '../Footer/Footer'
import { Outlet } from 'react-router-dom';
export default function Layout() {
  return (

    <div className='flex flex-row w-full h-full overflow-hidden'>
      <Sidebar />
      <div className='flex flex-col w-full h-screen overflow-hidden'>
        <Topbar />
        <div className='min-w-screen w-full overflow-y-auto'>
        {<Outlet />}
        <Footer />
        </div>
      </div>
    </div>
  );
};
