import React from 'react'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
  return (
    <section className='bg-bgcolor font-Jost'>
    <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 max-w-screen-sm'>
    <Link to='/login' className='flex items-center mb-6 text-2xl font-semibold text-titlecolor'>
    <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" />
      eRFC   
    </Link>
    <div className='bg-bgcomponents w-full flex flex-row rounded-lg md:p-6'>
        <div className='p-10 space-y-4 md:space-y-6 sm:p-8 flex flex-col justify-center mx-auto w-full'>
            <h1 className="text-heading-lg font-bold leading-tight tracking-tight text-titlecolor md:text-2xl">
                Recuperare parola
            </h1>
            <form className="space-y-4 md:space-y-6" action="#">
                <div>
                  <label for="email" className="block mb-2 text-body-lg font-medium text-textcolor">Email utilizator</label>
                  <input type="email" name="email" id="email" className="bg-gray-50 border border-bordercolor text-titlecolor sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5" placeholder="Email" required="" />
              </div>

              <button type="submit" className="w-full text-white bg-primary hover:bg-primarydarkened focus:ring-4 focus:outline-none focus:ring-primarydarker font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Trimite cod </button>
            </form>
        </div>
    </div>
    </div>
</section>
  )
}

export default ForgotPassword