import { Dot } from "lucide-react"

const HeaderPage = ({ currentPage, currentIcon }) => {


  return (
    <div className='flex flex-row px-12 py-4 items-center justify-between font-Jost bg-bgcolor'>
      <div className='text-titlecolor text-heading-lg font-closebold leading-body'>{currentPage}</div>
        <nav>
        <ol className="flex flex-row">
            <li><a href="/" className="flex flex-row gap-2 items-center justify-center text-sm text-textcolor hover:text-primary">{currentIcon}{currentPage}<Dot /></a></li>
        </ol>
        </nav>
    </div>
  )
}

export default HeaderPage