import React from "react";
import PrimaryButton from '../Buttons/PrimaryButton';
import { useForm } from 'react-hook-form';
import {useAddAngajat, useEditAngajat, useVerifyEmail} from "../../../services/apiAngajati";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const EmployeeForm = ({ formType, angajat, onClose }) => {

  const defaultValues =  angajat ? {
    name: angajat.name || '',
    email: angajat.email || '',
    password: '',
    status: angajat.status || '',
  } : undefined;


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue} = useForm();

  React.useEffect(() => {
    if (angajat) {
      setValue('name', angajat.name || '');
      setValue('email', angajat.email || '');
      setValue('password', angajat.password || '');
      setValue('status', angajat.status || '');
    }
  }, [angajat, setValue]);

  const {mutate: addAngajat} = useAddAngajat();
  const {mutate: verifyEmail} = useVerifyEmail();
  const {mutate: editAngajat} = useEditAngajat();


  const onSubmit = (data) => {
    if (formType === 'add') {
      verifyEmail(data.email, {
        onSuccess: (result) => {
          if (result) {
            toast.error('Un angajat cu acest email exista deja');
          } else {
            addAngajat(data, {
              onSuccess: () => {
                toast.success('Angajatul a fost adaugat cu succes!');
                onClose();
              },
              onError: (error) => {
                console.error('Error adding employee:', error);
                toast.error('A aparut o eroare în timpul adaugarii angajatului.');
              }
            });
          }
        },
        onError: (error) => {
          console.error('Error verifying email:', error);
          toast.error('A aparut o eroare în timpul verificarii emailului.');
        }
      });
    } else if(formType === 'edit'){
      editAngajat({...data, id:angajat.id}, {
        onSuccess: () => {
          toast.success('Angajatul a fost actualizat cu succes!');
          onClose();
        },
        onError: (error) => {
          console.error('Error updating employee:', error);
          toast.error('A aparut o eroare în timpul actualizarii angajatului.');
        }
      });
    }
  };

  return (
    <>
      <h1 className='flex mx-0 font-Jost text-titlecolor font-semibold text-heading-lg py-4 justify-center items-center'>
        {formType === 'add' ? 'Adaugati angajat' : 'Editati angajat'}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}  key={angajat ? angajat.id : 'add'}>
        <label
          htmlFor='name'
          className='block mb-2 text-body-lg font-medium text-textcolor'
        >
          Nume
        </label>
        <input
          type='text'
          className='bg-bgcolor border border-bordercolor text-titlecolor sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 mb-2'
          placeholder='Nume angajat'
          {...register('name', {
            required: 'Numele este obligatoriu!',
            minLength: {
              value: 3,
              message: 'Numele trebuie sa aiba cel putin 3 caractere!',
            },
          })}
        />
        {errors.name && (
          <div className='font-Jost text-body-base text-red-500'>
            {errors.name.message}
          </div>
        )}
        <label
          htmlFor='email'
          className='block mb-2 text-body-lg font-medium text-textcolor'
        >
          Email
        </label>
        <input
          type='email'
          className='bg-bgcolor border border-bordercolor text-titlecolor sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 mb-2'
          placeholder='Email angajat'
          {...register('email', {
            required: 'Email-ul este obligatoriu!',
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: 'Email-ul trebuie sa fie valid!',
            },
          })}
        />
        {errors.email && (
          <div className='font-Jost text-body-base text-red-500'>
            {errors.email.message}
          </div>
        )}
        <label
          htmlFor='password'
          className='block mb-2 text-body-lg font-medium text-textcolor'
        >
          Parola
        </label>
        <input
          type='password'
          className='bg-bgcolor border border-bordercolor text-titlecolor sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 mb-2'
          placeholder='••••••'
          {...register('password', {
            validate: (value) => {
              // DE FACUT CA PAROLA SA FIE OPTIONALA PENTRU MODALUL DE EDIT
              if(formType === 'edit' && value === '') return true;
              if(formType === 'add' && value === '') return 'Parola este obligatorie';
              if (value.length < 8) return 'Parola trebuie sa aiba cel putin 8 caractere!';
              return true;
            },
          })}
        />
        {errors.password && (
          <div className='font-Jost text-body-base text-red-500'>
            {errors.password.message}
          </div>
        )}
        <label
          htmlFor='status'
          className='block mb-2 text-body-lg font-medium text-textcolor'
        >
          Status
        </label>
        <select
          id='status'
          className='bg-bgcolor border border-bordercolor text-textcolor text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5'
          {...register('status', {
            required: 'Este obiligatoriu sa alegeti o optiune!',
          })}
        >
          <option value='Active'>Activ</option>
          <option value='Inactive'>Inactiv</option>
        </select>
        {errors.status && (
          <div className='font-Jost text-body-base text-red-500'>
            {errors.status.message}
          </div>
        )}
      </form>
      <div className='px-2 pt-4'>
        <PrimaryButton
          bgColor='bgprimary'
          btnText={
            formType === 'add' ? 'Adauga angajat' : 'Salveaza modificarile'
          }
          onClick= {handleSubmit(onSubmit)}
        />
      </div>
    </>
  );
};

export default EmployeeForm;
