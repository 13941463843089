import {useSearchAngajat} from "../../../services/apiAngajati";
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';


const SearchBar = ({onSearchChange}) => {

    const {
        register,
        handleSubmit,
    } = useForm();

    const onSubmit = (data) => {
        onSearchChange(data.search)
    }

  return (
    <form className='max-w-md pb-2' onSubmit={handleSubmit(onSubmit)}>
      <label
        className='mb-2 text-sm font-medium text-gray-900 sr-only'
      >
        Search
      </label>
      <div className='relative'>
        <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
          <svg
            className='w-4 h-4 text-textcolor'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 20 20'
          >
            <path
              stroke='currentColor'

              d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
            />
          </svg>
        </div>
        <input
          type='search'
          className='block w-full p-4 ps-10 text-sm text-gray-900 border border-bordercolor rounded-lg bg-bgcolor focus:ring-primary focus:border-primary '
          placeholder='Cauta...'
          {...register('search')}
        />
        <button
          type='submit'
          className='text-white absolute end-2.5 bottom-2.5 bg-primary hover:bg-primarydarkened focus:ring-4 focus:outline-none focus:ring-primarylighter font-medium rounded-lg text-sm px-4 py-2'
        >
          Cauta
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
