import { ArrowLeft, ArrowRight, Building2, Handshake, Home, User } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tooltip from "../ui/Tooltip/Tooltip";
const Sidebar = () => {

  const [expanded, setExpanded] = useState(true);

  const [activePage, setActivePage] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setExpanded(window.innerWidth >= 800); // Set your desired breakpoint
    };

    // Initial call to handleResize
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const handleClickPage = (pageName) => {
    setActivePage(pageName);
  }

  const handleClickExpand = () => {
    setExpanded(!expanded);
  }
  
  const menuList = [
        {
          link: '/',
          icon: <Home />,
          text: 'Acasa',
        },
        {
          link: '/angajati',
          icon: <User />,
          text: 'Angajati',
        },
        {
          link: '/clienti',
          icon: <Handshake />,
          text: 'Clienti',
        },
        {
          link: '/firme',
          icon: <Building2 />,
          text: 'Firme',
        },

  ]

  return (
    <>
    <div className={`flex flex-col gap-10 min-h-screen top-0 bg-bgcomponents text-titlecolor font-Jost font-semibold ${expanded ? 'w-[280px]' : 'w-[70px]'}`}>
      <div className="flex flex-row justify-between py-7 px-6 ">
    <h1 className={`${expanded ? '' : 'hidden'}`}>Logoooo</h1>
    <button
            onClick={handleClickExpand}
            className={`rounded-lg ${
              expanded ? '' : 'items-center justify-center'
            }`}
          >
            {expanded ? <ArrowLeft /> : <ArrowRight />}
          </button>
      </div>
      <ul className="flex flex-col ">
     {menuList.map((item,idx) => (
       <p
       className={`transition-all duration-500 ${
         expanded
          ? 'mt-2'
          : 'flex flex-col items-center justify-center p-2 text-titlecolor '
      }`}
      key={idx}
      >
      {expanded ? (
        <Link to={item.link} className={`flex flex-row items-center rounded-r-full p-4 bg-bgcomponents hover:text-primary ${activePage === item.text ? 'bg-bgprimary text-primary' : ''}`} onClick={() => handleClickPage(item.text)}>
        <li key={idx} className="flex flex-row gap-3">
          {item.icon}
          {item.text}
        </li>
      </Link>
      ) : (
        <Tooltip content={item.text} direction="right">
        <Link to={item.link} className={`flex flex-row items-center rounded-r-full p-4 bg-bgcomponents hover:text-primary ${activePage === item.text ? 'bg-bgprimary text-primary' : ''}`} onClick={() => handleClickPage(item.text)}>
              <li key={idx} className="flex flex-row gap-3">
                {item.icon}
              </li>
            </Link>
            </Tooltip>
      )}
    
        </p>
     ))}
      </ul>
    </div>
  </>
  );
};

export default Sidebar;
