import apiService from "./apiService";
import {useMutation, useQueryClient} from "react-query";


export const fetchAngajati = async () => {
    const response = await apiService.get('/angajati');
    return response.data;
};

//ADD ANGAJAT
const postAngajat = async (angajatData) => {
    const response = await apiService.post('/angajati', angajatData);
    return response;
}

export const useAddAngajat = () => {
    const queryClient = useQueryClient();

    return useMutation(postAngajat, {
        onSuccess: () => {
            queryClient.invalidateQueries('angajati')
        },
        onError: (error) => {
            console.error('Error to adding employee: ', error);
        }
    })
}

//VERIFY FOR UNIQUE ANGAJAT
 const verifyAngajatiEmail = async (email) => {
    const response = await apiService.get(`/angajati?filter[search]=${email}`)
    const angajati = response.data.map(angajat => angajat.email);
    return angajati.includes(email);
}
export const useVerifyEmail = ()=> {
    return useMutation(verifyAngajatiEmail);
}

// MODIFY EXISTING ANGAJAT

const editAngajat = async (data) => {
    const response = await apiService.patch(`/angajati/${data.id}`, {...data});
    return response.data;
    console.log(response.data)
}

export const useEditAngajat = () => {
    return useMutation(editAngajat)
    }

//DELETE ANGAJAT

const deleteAngajat = async (data) => {
    const response = await apiService.delete(`/angajati/${data.id}`)
    return response.data;
}

export const useDeleteAngajat = () => {
    return useMutation(deleteAngajat);
}

//SEARCH ANGAJATI

const searchAngajat = async(data) => {
    const response = await apiService.get(`/angajati?filter=[search]=${data}&perPage=10`);
    return response.data;
}

export const useSearchAngajat = () => {
    return useMutation(searchAngajat);
}
