import React from 'react';
import HeaderPage from '../HeaderPage/HeaderPage';
import { Home } from 'lucide-react';
import CardHomepage from '../ui/CardHomepage/CardHomepage'

const OwnerContaContent = () => {
  return (
    <>
    <HeaderPage currentPage='Acasa' currentIcon={<Home size={18} />}/>
    <main className='bg-bgcolor h-screen px-12 py-4 max-w-screen'>
      <CardHomepage />
    </main>
    </>
  )
};

export default OwnerContaContent;
