import { X } from 'lucide-react';
import EmployeeForm from '../Forms/EmployeeForm';
import DeleteForm from '../Forms/DeleteForm';

const ModalComponent = ({ isOpen, onClose, modalType, angajat, childText }) => {

    const renderForm = () => {
        switch (modalType) {
          case 'add':
            return <EmployeeForm formType='add' onClose={onClose} angajat={angajat}/>;
          case 'edit':
            return <EmployeeForm formType='edit' onClose={onClose} angajat={angajat}/>;
          default:
            return <DeleteForm childText={childText} onClose={onClose} angajat={angajat}/>
        }
      };

  return (
    <div
      onClick={onClose}
      className={`fixed inset-0 flex justify-center items-center transition-colors backdrop-blur-sm	z-50 ${
        isOpen ? 'visible bg-bgcolor/50 border' : 'invisible'
      }`}
    >
      <div
        onClick={e => e.stopPropagation()}
        className={`bg-bgcomponents rounded-xl shadow p-6 transition-all max-w-screen-lg w-full md:w-1/2 lg:w-1/4 ${
          isOpen ? 'scale-100 opacity-100' : 'scale-125 opacity-0'
        }`}
      >
        <button
          onClick={onClose}
          className='absolute top-2 right-2 p-1 rounded-lg text-textcolor bg-bgcolor hover:text-titlecolor'
        >
          <X />
        </button>
        <div className='p-2 w-full '>
        {renderForm()}
        </div>
      </div>

    </div>

  );
};

export default ModalComponent;
