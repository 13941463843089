import React from 'react';
import { ChevronDown, LogOut, Settings, User2 } from "lucide-react";
import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import apiService from '../../services/apiService';

const Topbar = () => {
  const username = 'DOTWEBIM';
  const firstChar = username.charAt(0).toUpperCase();
  const lastChar = username.charAt(username.length - 1).toUpperCase();
  const navigate = useNavigate();

  const handleLogout = () => {
    apiService.removeToken();
    navigate('/login');
  };

  const items = [
    {
      key: '1',
      label: (
        <a href='/profile' rel="noopener noreferrer" className="flex flex-row gap-2 p-2">
          <User2 />
          Profile
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a href='/settings' rel="noopener noreferrer" className="flex flex-row gap-2 p-2">
          <Settings />
          Settings
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <button onClick={handleLogout} className="flex flex-row gap-2 p-2 w-full text-left">
          <LogOut />
          Deconectare
        </button>
      ),
    },
  ];

  return (
    <div className='bg-bgcomponents font-Jost text-body-sm px-layoutlg h-16 flex flex-row justify-end w-full z-10 py-4'>
      <div className="flex justify-center items-center">
        <button className="flex gap-2 items-center text-sm pe-1 font-medium text-titlecolor rounded-full" type="button">
          <div className="w-10 h-10 rounded-full bg-primary flex items-center justify-center p-4 font-Jost font-semibold text-white text-body-sm">
            {firstChar}{lastChar}
          </div>
          {username}
          <Dropdown menu={{ items }} placement="bottomLeft" arrow>
            <ChevronDown />
          </Dropdown>
        </button>
      </div>
    </div>
  );
};

export default Topbar;
