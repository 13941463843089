import React from 'react';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import { User } from 'lucide-react';
import DataTable from '../../components/ui/Tables/DataTable';
import {useQuery} from "react-query";
import {fetchAngajati} from "../../services/apiAngajati";

const Employees = () => {

  const {data: angajati, isLoading, error } = useQuery({
    queryFn: () => fetchAngajati(),
    queryKey: 'angajati',
    refetchInterval: 5000,
  });

  if (isLoading) {
    return <div>Loading...</div>
  }

  if(error) {
    return <div>error...</div>
  }

  return (
    <>
      <HeaderPage currentPage='Angajati' currentIcon={<User size={18} />} />
      <div className='bg-bgcolor h-screen sm:px-1 md:px-12 py-4 max-w-screen'>
        <DataTable data={angajati} />
      </div>
    </>
  );
};

export default Employees;
