import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import apiService from '../services/apiService'; // Adjust the import path as necessary


const ProtectedRoute = () => {
    const token = apiService.getToken();
    return token ? <Outlet /> : <Navigate to="/login" />;
  };
  
export default ProtectedRoute;

