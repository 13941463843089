import {BASE_URL} from '../utils/apiRoutes'

const getToken = () => {
  return localStorage.getItem('token');
};

const setToken = token => {
  localStorage.setItem('token', token);
};

const removeToken = () => {
  localStorage.removeItem('token');
};

const request = async (endpoint, method = 'GET', data = null) => {
  const url = `${BASE_URL}${endpoint}`;
  const token = getToken();

  if (!token && endpoint !== '/login') {
    throw new Error('No token found. Please log in.');
  }

  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Unauthorized access. Please log in again.');
      }
      const errorData = await response.json();
      throw new Error(errorData.message || 'Something went wrong');
    }

    return await response.json();
  } catch (error) {
    console.error('API request error:', error);
  }
};

const apiService = {
  get: endpoint => request(endpoint, 'GET'),
  post: (endpoint, data) => request(endpoint, 'POST', data),
  patch: (endpoint, data) => request(endpoint, 'PATCH', data),
  put: (endpoint, data) => request(endpoint, 'PUT', data),
  delete: endpoint => request(endpoint, 'DELETE'),
  getToken,
  setToken,
  removeToken,
};

export default apiService;
