// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout.jsx';
import './index.css';
import Employees from './screens/employees/Employees.jsx';
import OwnerContaContent from './components/MainContent/OwnerContaContent.jsx';
import Login from './screens/auth/login/Login.jsx';
import ForgotPassword from './screens/auth/forgotpass/ForgotPassword.jsx';
import ProfilePage from './screens/profile/ProfilePage.jsx';
import Settings from './screens/settings/Settings.jsx';
import ProtectedRoute from './components/ProtectedRoute.jsx';
import {
  QueryClient,
  QueryClientProvider,Re
} from 'react-query'
import { ToastContainer } from 'react-toastify';


const App = () => {

  const queryClient = new QueryClient();

  return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />

            <Route path='/' element={<Layout />}>
              <Route element={<ProtectedRoute />}>
                <Route path='angajati' element={<Employees />} />
                <Route index element={<OwnerContaContent />} />
                <Route path='clienti' element={<Employees />} />
                <Route path='firme' element={<Employees />} />
                <Route path='profile' element={<ProfilePage />} />
                <Route path='settings' element={<Settings />} />
              </Route>
            </Route>
          </Routes>
        </Router>
        <ToastContainer />
      </QueryClientProvider>
  );
};

export default App;
