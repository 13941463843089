// src/screens/auth/login/Login.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import apiService from '../../../services/apiService'; 
import { useForm } from 'react-hook-form';


const Login = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { register, formState: {errors}, handleSubmit } = useForm(); 

  const onSubmit = async (data) => {
    try {
      const response = await apiService.post('/login', data);
      const token = response.access_token;
      if (token) {
        apiService.setToken(token);
        setError(null);
        navigate('/'); 
      } else {
        setError('Login failed: No token received');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <section className='bg-bgcolor font-Jost'>
      <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 max-w-screen-sm'>
        <Link to='/login' className='flex items-center mb-6 text-2xl font-semibold text-titlecolor'>
          <img className="w-8 h-8 mr-2" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg" alt="logo" />
          eRFC
        </Link>
        <div className='bg-bgcomponents w-full flex flex-row rounded-lg md:p-6'>
          <div className='p-10 space-y-4 md:space-y-6 sm:p-8 flex flex-col justify-center mx-auto w-full'>
            <h1 className="text-heading-lg font-bold leading-tight tracking-tight text-titlecolor md:text-2xl">
              Conectare la cont
            </h1>
            {error && <p className="text-red-500">{error}</p>}
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="email" className="block mb-2 text-body-lg font-medium text-textcolor">Email utilizator</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-bordercolor text-titlecolor sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                  placeholder="Email utilizator"
                  {...register('email', {
                    required: 'Email-ul este obligatoriu!',
                    pattern: {
                      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: 'Email-ul trebuie sa fie valid!'
                    }
                  })}
                />
        {errors.email && <div className='font-Jost text-body-base text-red-500'>{errors.email.message}</div>}

              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-textcolor">Parola</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-bordercolor text-titlecolor sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                  {...register('password', {
                    required: 'Parola este obligatorie',
                    minLength: {
                        value: 8,
                        message: 'Parola trebuie sa aiba cel putin 8 caractere!'
                    }
                  })}
                />
        {errors.password && <div className='font-Jost text-body-base text-red-500'>{errors.password.message}</div>}

              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="bg-gray-50 border border-bordercolor text-titlecolor sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="remember" className="text-gray-500">Pastreza-ma logat</label>
                  </div>
                </div>
                <Link to='/forgot-password' className="text-sm font-medium text-primary hover:underline">Ai uitat parola?</Link>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary hover:bg-primarydarkened focus:ring-4 focus:outline-none focus:ring-primarydarker font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                onClick={handleSubmit(onSubmit)}
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;