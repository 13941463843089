const PrimaryButton = ({ bgColor, btnText, onClick }) => {


  return (
    <button
      className={`bg-${bgColor} font-Jost text-body-base font-closebold text-primary p-3 rounded-lg focus:ring-2 focus:outline-none focus:ring-primarylighter`}
      onClick={onClick}
    >
      {btnText}
    </button>
  );
};

export default PrimaryButton;
