import { Building2, Handshake, NotepadTextDashed, UsersRound } from 'lucide-react'
import React from 'react'

const CardHomepage = () => {

    const cardItems = [
        {
            title: 'Numar Angajati',
            data: 18,
            subtitle: 'de ultima luna',
            valSubtitle: 2,
            icon: <UsersRound size={30} />,
        },
        {
            title: 'Numar Clienti',
            data: 259,
            subtitle: 'de ultima luna',
            valSubtitle: 19,
            icon: <Handshake size={30} />,
        },
        {
            title: 'Numar Firme',
            data: 301,
            subtitle: 'de ultima luna',
            valSubtitle: 21,
            icon: <Building2 size={30} />,
        },
        {
            title: 'Numar RFC-uri',
            data: 18345,
            subtitle: 'de ultima luna',
            valSubtitle: 1045,
            icon: <NotepadTextDashed size={30} />,
        }
    ]

    const cardColors = [
        {
            id: 1,
            bgcolor: 'bg-bgprimary',
            textcolor: 'text-primary'
        },
        {
            id: 2,
            bgcolor: 'bg-bgsecondary',
            textcolor: 'text-secondary'
        },
        {
            id: 3,
            bgcolor: 'bg-bgsucces',
            textcolor: 'text-success'
        },
        {
            id: 4,
            bgcolor: 'bg-bgthird',
            textcolor: 'text-third'
        },
    ]

  return (
<div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
      {cardItems.map((item, id) => (
        <div key={id} className='w-full  h-[140px] bg-white rounded-lg shadow-xl flex flex-row justify-between'>
          <div className='flex flex-col w-3/4 justify-center p-4'>
            <p className='text-titlecolor text-body-lg font-Jost'>{item.title}</p>
            <h1 className='text-titlecolor text-heading-xxl font-bold'>{item.data}</h1>
            <div className='flex flex-row'>
            <p className={`${cardColors[id].textcolor} mr-2 text-body-lg font-bold font-Jost`}>{item.valSubtitle}</p>
            <p className='text-textcolor font-Jost'>{item.subtitle}</p>
            </div>
          </div>
          <div className={`flex items-center justify-center w-1/4 p-4 rounded-l-full ${cardColors[id].bgcolor}`}>
          {React.cloneElement(item.icon, { className: cardColors[id].textcolor })}
            </div>
        </div>
      ))}
    </div>
  )

}


export default CardHomepage