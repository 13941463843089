import React from 'react';

const Footer = () => {
  return (
    <div className='w-full bg-bgcomponents flex flex-row p-4 gap-2 font-Jost font-regular top-0'>
      <span>© 2024</span><a href='/' className='text-primary font-semibold'>DOTWEBIM</a>
    </div>
  )
};

export default Footer;
