import { Pencil, Trash2 } from 'lucide-react';
import SearchBar from '../SearchBar/SearchBar';
import PrimaryButton from '../Buttons/PrimaryButton';
import { useState } from 'react';
import ModalComponent from '../Modals/ModalComponent';
import {useSearchAngajat} from "../../../services/apiAngajati";

const DataTable = ({ data, onSearchChange }) => {

  const [modalType, setModalType] = useState('add');
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('')
  const [searchData, setSearchData] = useState([])

  const handlerModalComponent = (value,type, employee) => {
    setModalType(type);
    setCurrentEmployee(employee);
    setIsModalOpen(value);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const {mutate:searchMutate} = useSearchAngajat({
    onSuccess: (searchData) => {
      setSearchData(searchData);
    },
  })

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    searchMutate(query)
  }

  return (
    <div className='overflow-x-auto bg-bgcomponents px-6 py-4 rounded-2xl'>
      <div className='bg-bgcomponents flex flex-col sm:flex-row align-center justify-between py-2'>
        <div className='w-full lg:w-1/4'>
          <SearchBar onSearchChange={handleSearchChange} />
        </div>
        <div className='w-full sm:justify-start lg:w-1/4 flex md:justify-end py-2'>
          <PrimaryButton bgColor='bgprimary' btnText='Adauga angajat' onClick={() => handlerModalComponent(true, 'add')}/>
        </div>
      </div>
          <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal} modalType={modalType} angajat={currentEmployee} childText='acest angajat'/>


      
      <table className='min-w-full'>
        <thead>
          <tr className='bg-bgcolor'>
            <th
              scope='col'
              className='px-6 py-3 text-left text-body-lg font-Jost font-semibold  text-titlecolor tracking-wider rounded-l-xl'
            >
              Nume
            </th>
            <th
              scope='col'
              className='px-6 py-3 text-left text-body-lg font-Jost font-semibold  text-titlecolor tracking-wider'
            >
              Email
            </th>
            <th
              scope='col'
              className='px-6 py-3 text-left text-body-lg font-Jost font-semibold  text-titlecolor tracking-wider'
            >
              Data
            </th>
            <th
              scope='col'
              className='px-6 py-3 w-40 text-left text-body-lg font-Jost font-semibold  text-titlecolor tracking-wider'
            >
              Status
            </th>
            <th
              scope='col'
              className='px-6 py-3 w-40 text-right right-0 text-body-lg font-Jost font-semibold  text-titlecolor tracking-wider rounded-r-xl'
            >
              Actiuni
            </th>
          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-200'>
          {data.map(angajat => (
            <tr key={angajat.id}>
              <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-titlecolor'>
                {angajat.name}
              </td>
              <td className='px-6 py-4 whitespace-nowrap text-sm text-titlecolor'>
                {angajat.email}
              </td>
              <td className='px-6 py-4 whitespace-nowrap text-sm text-titlecolor'>
                {angajat.created_at}
              </td>
              <td
                className={`px-6 py-4 whitespace-nowrap text-body-sm ${
                  angajat.status === 1 ? 'text-success' : 'text-third'
                } font-Jost font-semibold`}
              >
                <span
                  className={`${
                    angajat.status === 1 ? 'bg-bgsucces' : 'bg-bgthird'
                  } px-3 py-1 rounded-full`}
                >
                  {angajat.status === 1 ? 'Activ' : 'Inactiv'}
                </span>
              </td>
              <td className='px-6 py-4 whitespace-nowrap flex flex-row justify-end gap-2 '>
                <button className='text-textcolor hover:text-secondary' onClick={() => handlerModalComponent(true,'edit', angajat)}>
                  <Pencil size={18} />
                </button>
                <button className='text-textcolor hover:text-red-500' onClick={() => handlerModalComponent(true, 'acest angajat', angajat)}>
                  <Trash2 size={18} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
