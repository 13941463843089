import { useRef, useState } from "react";

const Tooltip = ({ direction = 'right', content, children }) => {

  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef();

  const handleMouseOver = () => setVisible(true);
  const handleMouseOut = () => setVisible(false);


  const directionClasses = {
    top: 'bottom-full transform -translate-x-1/2 mb-2',
    right: 'left-full transform -translate-y-1 ml-2 top-4',
    bottom: 'top-full transform -translate-x-1/2 mt-2',
    left: 'right-full transform -translate-y-1/2 mr-2',
  };

  return (
    <div className="relative inline-block" ref={tooltipRef} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {children}
      {visible && (
        <div className={`absolute ${directionClasses[direction]} bg-bgprimary text-primary text-sm py-2 px-3 rounded shadow-lg whitespace-nowrap`}>
          {content}
        </div>
      )}
    </div>
  )
}

export default Tooltip;